import { gql } from '@apollo/client';

/*
  Get countries
 */

const GET_USER_DATA = gql`
  query UserData {
    user {
      id
      name
      phone
      email
    }
    addressByUser {
      id
      line_one
      line_two
      city
      state
      country
      zipcode
    }
  }
`;

const GET_USER = gql`
  query User {
    user {
      id
      name
      phone
      email
      org_id
    }
  }
`;

const GET_USER_ORG = gql`
  query UserOrg {
    userOrg {
      id
      name
      logo
      phone
      email
      spaceId
    }
  }
`;

const GET_CONTENT_SAVED_BY_USER = gql`
  query ContentSavedByUser {
    contentSavedByUser {
      id
      title
      short_description
      content
      media_urls
      content_type
      view_time
      tags
      creator
      created_at
      no_of_likes
      no_of_saves
    }
  }
`;

const GET_USER_ADDRESS = gql`
  query AddressByUser {
    addressByUser {
      id
      line_one
      line_two
      city
      state
      country
      zipcode
    }
  }
`;
const GET_USER_PLANT_SUBSCRIPTIONS = gql`
  query UserPlantSubscriptions {
    userPlantSubscriptions {
      user_subs_id
      plant_id
      plant_name
      scientific_name
      short_description
      image_url
      subscription_date
      status
    }
  }
`;

const GET_ORG_DASHBOARD_DATA = gql`
  query OrgDashboardData {
    getOrgDashboardData {
      aiPlantHealth
      aqiSensor
      lastUpdatedOn
      noOfPlants
      noOfZones
      orgLogoPath
      orgName
      soilSensor
      weatherAPI
      totalWaterUsed
      alertsByDate {
        water {
          date
          count
        }
        nutrient {
          date
          count
        }
        pest {
          date
          count
        }
        light {
          date
          count
        }
      }
      alertsByPlacement {
        water {
          placement
          count
        }
        nutrient {
          placement
          count
        }
        pest {
          placement
          count
        }
        light {
          placement
          count
        }
      }
      plantsWithSensorData {
        id
        plantId
        name
        soilMoisture
        soilPH
        temperature
        humidity
        light
        zone
        status
        image
      }
      zoneData {
        id
        name
        extId
        coordinates {
          levelIndex
          x
          z
        }
        costSavings
        constSavingsDelta
        waterUsed
        waterUsedDelta
        aqi
        aqiDelta
        temperature
        temperatureDelta
        humidity
        humidityDelta
        natureScore
        area
        solutions {
          name
          percentage
        }
        density
        diversity
        compliance
        complianceDelta
        complianceGraph
        pendingActionsCount
        pendingActionsCountDelta
        alertsCount
        alertsCountDelta
        pendingActions {
          actions
          assignedTo
          date
          id
          name
          image
          plantId
          position {
            elevation
            levelIndex
            x
            z
          }
        }
        alerts {
          assignedTo
          date
          desc
          id
          level
          name
          image
          plantId
          position {
            elevation
            levelIndex
            z
            x
          }
        }
        plants {
          name
          id
          image
          plantId
          position {
            elevation
            levelIndex
            x
            z
          }
        }
      }
    }
  }
`;

const GET_ORG_PLANT_DATA = gql`
  query OrgPlantData {
    getOrgPlantInfo {
      id
      name
      imageUrl
      barcode
      placement
      light
      lastWateredAt
      healthStatus
    }
  }
`;

const GET_ORG_MAINTENANCE_DATA = gql`
  query OrgMaintenanceData {
    getOrgSubscriptionInfo {
      id
      noOfUsers
      botSessions
      botSessionsCompleted
      expertSessions
      expertSessionsCompleted
      upcomingSession
    }
  }
`;

const GET_ORG_PLAN_DATA = gql`
  query OrgPlanData {
    getOrgSubscriptionInfo {
      id
      orgName
      orgId
      name
      startDate
      endDate
      nextPaymentDue
      noOfUsers
      botSessions
      expertSessions
      eligibleReplacements
      nextPayment
      totalPrice
    }
  }
`;

/*
  Get files
 */

const GET_ORG_FILES = gql`
  query OrgFiles {
    getOrgFiles {
      orgName
      files {
        id
        name
        url
        category
        createdAt
      }
    }
  }
`;

const GET_ORG_REPORT_DATA = gql`
  query OrgReportData(
    $orgId: String!
    $dateRange: DateRangeInput!
    $reportName: String!
  ) {
    getOrgReportData(
      orgId: $orgId
      dateRange: $dateRange
      reportName: $reportName
    ) {
      orgName
      keyMetrics {
        plantHealth
        maintenanceSessions
        compliance
        smartActions
        alerts
      }
      areaDetails {
        zones
        builtArea
        plantDistribution
      }
      orgLogoPath
      spaceId
      zoneData {
        id
        name
        costSavings
        costSavingsDelta
        waterUsed
        waterUsedDelta
        aqi
        aqiDelta
        temperature
        temperatureDelta
        humidity
        humidityDelta
        natureScore
        area
        extId
        coordinates
        density
        diversity
        compliance
        complianceDelta
        pendingActionsCountDelta
        alertsCountDelta
        pendingActions {
          actions
          plantId
          name
          date
          position
          id
          image
        }
      }
      alertHistory {
        id
        message
        severity
        timestamp
      }
      sensorData {
        humidityData {
          healthy_count
          unhealthy_count
          date
        }
        soilPhData {
          healthy_count
          unhealthy_count
          date
        }
        soilMoistureData {
          healthy_count
          unhealthy_count
          date
        }
      }
    }
  }
`;

export {
  GET_CONTENT_SAVED_BY_USER,
  GET_ORG_DASHBOARD_DATA,
  GET_ORG_FILES,
  GET_ORG_MAINTENANCE_DATA,
  GET_ORG_PLAN_DATA,
  GET_ORG_PLANT_DATA,
  GET_ORG_REPORT_DATA,
  GET_USER,
  GET_USER_ADDRESS,
  GET_USER_DATA,
  GET_USER_ORG,
  GET_USER_PLANT_SUBSCRIPTIONS,
};
