import '../../globals.css';

import { ApolloProvider } from '@apollo/client';
import { AuthDialogProvider } from '@contexts/AuthDialogContext';
import { CartCountContext } from '@contexts/CartContext';
import { UserDataProvider } from '@contexts/UserDataContext';
import { CacheProvider } from '@emotion/react';
import * as ga from '@lib/ga';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import createEmotionCache from '@theme/emotionCache';
import customTheme from '@theme/index';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';
import client from '../apolloConfig';

import 'react-toastify/dist/ReactToastify.css';

const clientSideEmotionCache = createEmotionCache();

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}) {
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  // adding hotjar
  useEffect(() => {
    hotjar.initialize(2529948, 6);
  }, []);

  // adding analytics
  const router = useRouter();
  const [cartCount, updateCartCount] = useState(null);

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <ApolloProvider client={client}>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>naturethings</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={customTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthDialogProvider>
              <UserDataProvider>
                <CartCountContext.Provider
                  value={{ cartCount, updateCartCount }}
                >
                  <CssBaseline />
                  <Component {...pageProps} />
                </CartCountContext.Provider>
              </UserDataProvider>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </AuthDialogProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </ApolloProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default appWithTranslation(MyApp);
